class FeatureFlag {

  #key
  #defaultValue

  constructor(key, defaultValue) {
    this.#key = key;
    this.#defaultValue = defaultValue;
  }

  get key() {
    return this.#key;
  }

  get defaultValue() {
    return this.#defaultValue;
  }

}

export default class FeatureFlags {
  static CAN_ADD_USERS = new FeatureFlag('can-add-users', false)

  /**
   * @returns {Array.<FeatureFlag>}
   */
  static all() {
    return [
      FeatureFlags.CAN_ADD_USERS
    ];
  }
}