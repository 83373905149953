import React from 'react';
import PropTypes from 'prop-types';
import cleanProps from 'clean-react-props';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import {Dropdown, ListGroup, ListGroupItem} from 'react-bootstrap';
import Avatar from 'src/components/Avatar';
import TenantMenuButton from 'src/menu/TenantMenuButton';

class MenuContainer extends React.PureComponent {

  handleRootClose = event => {
    this.props.onClose(event, {
      source: 'rootClose'
    });
  }

  render() {
    const {open, rootCloseEvent, children, ...props} = this.props;

    return (
      <RootCloseWrapper
        disabled={!open}
        onRootClose={this.handleRootClose}
        event={rootCloseEvent}
      >
        <div {...cleanProps(props)}>
          {children}
        </div>
      </RootCloseWrapper>
    );
  }

}

export default class TenantDropdownMenu extends React.PureComponent {

  static propTypes = {
    open: PropTypes.bool,
    tenant: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }),
    availableTenants: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })),
    className: PropTypes.string,
    onChange: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func
  };

  static defaultProps = {
    open: false,
    tenant: {},
    availableTenants: [],
    onChange: () => {},
    onOpen: () => {},
    onClose: () => {}
  }

  isCurrentTenant(tenant) {
    return tenant.id === this.props.tenant.id;
  }

  handleToggle = isOpen => {
    if (typeof(isOpen) !== 'boolean') {
      isOpen = !this.props.open;
    }

    if (isOpen) {
      this.handleOpen();
    } else {
      this.handleClose();
    }
  }

  handleOpen = () => {
    this.props.onOpen();
  }

  handleClose = () => {
    this.props.onClose();
  }

  handleTenantSelected = tenant => () => {
    this.props.onChange(tenant);
  }

  render() {
    const {open, tenant: currentTenant, availableTenants} = this.props;

    return (
      <Dropdown
        id="tenantMenu"
        open={open}
        className="tenant-dropdown-menu"
        onToggle={this.handleToggle}
        disabled={(!availableTenants || availableTenants.length <= 1)}
      >
        <TenantMenuButton
          bsRole="toggle"
          name={currentTenant.name}
          onClick={this.handleToggle}
        />

        <MenuContainer bsRole="menu">
          <div className="dropdown-menu">
            <ListGroup className="available-tenant-list">
              {availableTenants && availableTenants.map(tenant => (
                <ListGroupItem
                  key={tenant.id}
                  className="tenant-option"
                  active={this.isCurrentTenant(tenant)}
                  onClick={this.handleTenantSelected(tenant)}
                >
                  <div className="tenant-content">
                    <Avatar
                      name={tenant.name}
                      size="medium"
                      generateColor
                    />

                    <span className="tenant-name">
                      {tenant.name}
                    </span>
                  </div>
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        </MenuContainer>
      </Dropdown>
    )
  }

};
