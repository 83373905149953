import React from 'react';
import {
  SideNav,
  Dismiss,
  BrandLogo,
  TopBar,
  TopBarSpace,
  MenuToggle,
  useSiteMenuValue
} from 'uui_kit';
import NavigationMenu from 'src/menu/NavigationMenu';
import TenantMenu from 'src/menu/TenantMenu';
import UserMenu from 'src/menu/UserMenu';
import vectorSolutionsLogo from 'src/assets/VectorSolutionsLogo.png';

export default function NavigationBar() {
  const [,dispatchMenuToggle] = useSiteMenuValue();

  const handleMenuToggle = () => {
    dispatchMenuToggle({type: 'toggle'})
  };

  return (
    <>
      <TopBar>
        <TopBarSpace className="top-bar-toggle-menu">
          <MenuToggle handleMenuToggle={handleMenuToggle} />
        </TopBarSpace>

        <TopBarSpace className="top-bar-company-logo">
          <BrandLogo
            className="company-logo"
            name="Vector Solutions"
            logo_src={vectorSolutionsLogo}
            href="/"
          />
        </TopBarSpace>

        <TopBarSpace className="top-bar-tenant">
          <TenantMenu />
        </TopBarSpace>

        <TopBarSpace className="top-bar-user landing-top-user-menu">
          <UserMenu />
        </TopBarSpace>
      </TopBar>

      <SideNav className="landing-side-navigation">
        <Dismiss closeNav={handleMenuToggle} />

        <div className="uber-btn-space u-text-center visible-xs company-logo-mobile">
          <div className="u-pl-3 u-pr-3 u-color-gray-darker u-text-bold small u-width-p-12">
            <img
              src={vectorSolutionsLogo}
              alt="Vector Solutions"
              title="Vector Solutions"
              className="t-tip-right u-mb-2"
            />
          </div>
        </div>

        <NavigationMenu />
      </SideNav>
    </>
  );
}
